body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 17% !important;
}

/* .top-container {
  background-color: red;
  height: 100px;
} */

@font-face {
  font-family: 'NotoSansThai-Bold';
  src: local('NotoSansThai-Bold'), url(./assets/fonts/font-noto/NotoSansThai-Bold.ttf) format('ttf');
}
@font-face {
  font-family: 'NotoSansThai-SemiBold';
  src: local('NotoSansThai-SemiBold'), url(./assets/fonts/font-noto/NotoSansThai-SemiBold.ttf) format('ttf');
}
@font-face {
  font-family: 'NotoSansThai-Medium';
  src: local('NotoSansThai-Medium'), url(./assets/fonts/font-noto/NotoSansThai-Medium.ttf) format('ttf');
}
@font-face {
  font-family: 'NotoSansThai-Regular';
  src: local('NotoSansThai-Regular'), url(./assets/fonts/font-noto/NotoSansThai-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Arial-Bold';
  src: local('Arial-Bold'), url(./assets/fonts/Arial/Arial-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'Arial-Regular';
  src: local('Arial-Regular'), url(./assets/fonts/Arial/Arial-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Arial-Medium';
  src: local('Arial-Medium'), url(./assets/fonts/Arial/Arial-Medium.ttf) format('ttf');
}
